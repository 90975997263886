import Colors from './ThemeColor';

const DeviceSpecific = {
  getThumbColor(type) {
    switch (type) {
      case 'light':
        return Colors.bulbSecondary;
      case 'socket':
        return Colors.socketSecondary;
      case 'fan':
        return Colors.fanSecondary;
      case 'switch':
        return Colors.switchSecondary;
      case 'dw_sensor':
        return Colors.doorSecondary;
      case 'rgb_light':
        return Colors.green;
      case 'pir_sensor':
        return Colors.pirSecondary;
      case 'gas_sensor':
        return Colors.gasSecondary;
      case 'smoke_detector':
        return Colors.smokeSecondary;
      default:
        return Colors.switchSecondary;
    }
  },
  getSwitchColor(type, active) {
    switch (type) {
      case 'light':
        return [Colors.bulbPrimary1, Colors.bulbSecondary1];
      case 'socket':
        return [Colors.socketPrimary1, Colors.socketSecondary1];
      case 'fan':
        return [Colors.fanPrimary1, Colors.fanSecondary1];
      case 'switch':
        return [Colors.switchPrimary1, Colors.switchSecondary1];
      case 'dw_sensor':
        if (active) {
          return [Colors.doorPrimary, Colors.doorSecondary];
        } else {
          return [Colors.doorSecondary, Colors.red];
        }
      case 'rgb_light':
        return [Colors.blueRibbon, Colors.green];
      case 'pir_sensor':
        if (active) {
          return [Colors.pirPrimary, Colors.pirSecondary];
        } else {
          return [Colors.pirSecondary, Colors.red];
        }
      case 'gas_sensor':
        if (active) {
          return [Colors.gasPrimary, Colors.gasSecondary];
        } else {
          return [Colors.gasSecondary, Colors.red];
        }
      case 'smoke_detector':
        if (active) {
          return [Colors.smokePrimary, Colors.smokeSecondary];
        } else {
          return [Colors.smokeSecondary, Colors.red];
        }
      default:
        return [Colors.switchSecondary1, Colors.switchPrimary1];
    }
  },
  getSwitchIcon(type, active) {
    switch (type) {
      case 'water_level_controller':
        return 'water';
      case 'sliding_gate':
        return 'gate';
      case 'swing_gate':
        return 'gate';
      case 'shutter_gate':
        return 'gate';
      case 'turning_sliding_gate':
        return 'gate';
      case 'light':
        return active ? 'lightbulb-variant' : 'lightbulb-variant-outline';
      case 'socket':
        return active ? 'power-socket-us' : 'power-socket-us';
      case 'fan':
        return active ? 'fan' : 'ceiling-fan';
      case 'washing_machine':
        return active ? 'washing-machine' : 'washing-machine-off';
      case 'television':
        return active ? 'youtube-tv' : 'youtube-tv';
      case 'fridge':
        return active ? 'fridge' :'fridge';
      case 'dishwasher':
        return active ? 'dishwasher' : 'dishwasher';
      case 'hair_dryer':
        return active ? 'hair-dryer' : 'hair-dryer';
      case 'water_heater':
        return active ? 'water-boiler' : 'water-boiler';
      case 'set_top_box':
        return active ? 'set-top-box' : 'set-top-box';
      case 'cellphone_adapter':
        return active ? 'cellphone-charging' : 'cellphone-charging';
      case 'outdoor_lamp':
        return active ? 'coach-lamp' :  'coach-lamp';
      case 'track_light':
        return active ? 'track-light' : 'track-light';
      case 'vacuum_cleaner':
        return active ?  'vacuum' : 'vacuum';
      case 'hvac':
        return active ? 'hvac' : 'hvac-off';
      case 'desktop':
        return active ? 'desktop-mac-dashboard' : 'desktop-mac-dashboard';
      case 'tubelight':
        return active ? 'lightbulb-fluorescent-tube' : 'lightbulb-fluorescent-tube-outline';
      case 'ceilinglight':
        return active ? 'ceiling-light' : 'ceiling-light';
      case 'globelight':
        return active ? 'globe-light' : 'globe-light';
      case 'spotlight':
        return active ? 'spotlight' : 'spotlight';
      case 'stringlights':
        return active ? 'string-lights' : 'string-lights-off';
      case 'floodlight':
        return active ? 'light-flood-up' : 'light-flood-up';
      case 'striplight':
        return active ? 'led-strip-variant' : 'led-strip-variant';
      case 'laptop':
        return active ? 'laptop' : 'laptop';
      case 'desk_lamp':
        return active ? 'desk-lamp' : 'desk-lamp';
      case 'pump':
        return active ? 'pump' : 'pump';
      case 'solar_inverter':
        return active ? 'solar-power' : 'solar-power';
      case 'router':
        return active ? 'router-wireless' : 'router-wireless-off';
      case 'network_switch':
        return active ? 'router-network' : 'router-network';
      case 'printer':
        return active ? 'printer' : 'printer-off';
      case '3d_printer':
        return active ? 'printer-3d' : 'printer-3d';
      case 'smart_speaker':
        return active ? 'google-home' : 'google-home';
      case 'home_theater':
        return active ? 'speaker' : 'speaker-off';
      case 'cam_dvr':
        return active ? 'video-box' : 'video-box-off';
      case 'humidifier':
        return active ? 'air-humidifier' : 'air-humidifier-off';
      case 'air_purifier':
        return active ? 'air-purifier' : 'air-purifier';
      case 'curtain':
        return active ? 'curtains' : 'curtains-closed';
      case 'display':
        return 'youtube-tv';
      case 'ac':
        return 'air-conditioner';
      case 'switch':
        return 'power-standby';
      case 'irbm':
        return 'remote-tv';
      case 'stb':
        return 'remote-tv';
      case 'dvd':
        return 'remote-tv';
      case 'stereo':
        return 'remote-tv';
      case 'projector':
        return 'remote-tv';
      case 'pir_sensor':
        return active ? 'motion-sensor' : 'motion-sensor-off';
      case 'gas_sensor':
        return active ? 'fire-alert' : 'fire';
      case 'smoke_detector':
        return active ? 'smoke-detector' : 'smoke-detector-off';
      case 'dw_sensor':
        return active ? 'lock' : 'lock-open';
      case 'door_bell':
        return active ? 'doorbell-video' : 'doorbell';
      case 'tv':
        return 'youtube-tv';
      case 'motor':
        return 'pump';
      case 'rgb_light':
        return 'ceiling-light-multiple';
      default:
        return 'power';
    }
  },
};

export default DeviceSpecific;