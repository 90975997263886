import React, { useEffect, useState } from 'react';
import { Area } from '@ant-design/plots';

const AreaChart = ({ data }) => {
    const [mountData, setMountData] = useState();
    const [range, setRange] = useState({min:null, max:null});
    const config = range.min !=null && range.max !=null && {
        data,
        color: '#1890ff',
        xField: 'time',
        yField: 'value',
        areaStyle: { fillOpacity: 0.2 },
        axis: {
            y: { labelFormatter: '~s' },
        },
        // scale: {
        //     value: {
        //         max: range.min, 
        //         min: range.max,
        //     },
        // },
        line: {
            style: {
                stroke: '#1890ff',
                strokeWidth: 2,
            },
        },
    };
    useEffect(() => {
        const max = data && [...data]?.reduce((max, item) => {
            console.log(max, item.value);
            return Number(item.value) > max ? Number(item.value) : Number(max)
        }, 0);
        const min = data && [...data]?.reduce((min, item) => {
            console.log(min, item.value);
            return Number(item.value) < min ? Number(item.value) : Number(min)
        }, 500);
        console.log(max, min);
        range.min=min;
        range.max=max;
        setRange({...range});
        setMountData([...data]);

    }, [data])
    console.log(data);
    return (
        (mountData && mountData.length && range.min && range.max) ?
            <Area {...config} /> :
                <div style={{
                    width:'100%',
                    height:'300px',
                    background:'white',
                    color:'black',
                    display:'flex',
                    textAlign:'center',
                    alignItems:'center',
                    justifyContent:'center'

                }}>
                    <div class="lds-roller"><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div></div>
                </div>
    );
}    

export default AreaChart;
