/*!

*/
import {
  Card,
  Col,
  Row,
  Typography,
  Space,
} from "antd";

import { useState, useEffect } from "react";
import ThemeColor from "../assets/theme/ThemeColor"
import { UserService } from '../services/user_profile_api';
import { useLocation, useHistory } from "react-router-dom";
// import Hook from "../components/device/Hook";
import SwitchCard from "../components/device/SwitchComponent"
import SensorCard from "../components/device/SensorComponent";
import { User } from "../storage/store";
import CircleBarSpinner from "../components/common/CircleBarSpinner";
import EllipsisSpinner from "../components/common/EllipsisSpinner";
import { AntBarChart } from "../components/chart/AntBarChart";
import { AntDoubleBarChart } from "../components/chart/AntDoubleBarChart";

function Home() {
  const navigate = useHistory();
  const [sites, setSites] = useState(null);
  const [property, setProperty] = useState(null);
  const [isHovering, setIsHovering] = useState(false);
  const [graph1, setGraph1] = useState(null);
  const [graph2, setGraph2] = useState(null);

  const propertyId = User.getPropertyId();
  const dollor = [
    <svg
      width="22"
      height="22"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      key={0}
    >
      <path
        d="M8.43338 7.41784C8.58818 7.31464 8.77939 7.2224 9 7.15101L9.00001 8.84899C8.77939 8.7776 8.58818 8.68536 8.43338 8.58216C8.06927 8.33942 8 8.1139 8 8C8 7.8861 8.06927 7.66058 8.43338 7.41784Z"
        fill="#fff"
      ></path>
      <path
        d="M11 12.849L11 11.151C11.2206 11.2224 11.4118 11.3146 11.5666 11.4178C11.9308 11.6606 12 11.8861 12 12C12 12.1139 11.9308 12.3394 11.5666 12.5822C11.4118 12.6854 11.2206 12.7776 11 12.849Z"
        fill="#fff"
      ></path>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10 18C14.4183 18 18 14.4183 18 10C18 5.58172 14.4183 2 10 2C5.58172 2 2 5.58172 2 10C2 14.4183 5.58172 18 10 18ZM11 5C11 4.44772 10.5523 4 10 4C9.44772 4 9 4.44772 9 5V5.09199C8.3784 5.20873 7.80348 5.43407 7.32398 5.75374C6.6023 6.23485 6 7.00933 6 8C6 8.99067 6.6023 9.76515 7.32398 10.2463C7.80348 10.5659 8.37841 10.7913 9.00001 10.908L9.00002 12.8492C8.60902 12.7223 8.31917 12.5319 8.15667 12.3446C7.79471 11.9275 7.16313 11.8827 6.74599 12.2447C6.32885 12.6067 6.28411 13.2382 6.64607 13.6554C7.20855 14.3036 8.05956 14.7308 9 14.9076L9 15C8.99999 15.5523 9.44769 16 9.99998 16C10.5523 16 11 15.5523 11 15L11 14.908C11.6216 14.7913 12.1965 14.5659 12.676 14.2463C13.3977 13.7651 14 12.9907 14 12C14 11.0093 13.3977 10.2348 12.676 9.75373C12.1965 9.43407 11.6216 9.20873 11 9.09199L11 7.15075C11.391 7.27771 11.6808 7.4681 11.8434 7.65538C12.2053 8.07252 12.8369 8.11726 13.254 7.7553C13.6712 7.39335 13.7159 6.76176 13.354 6.34462C12.7915 5.69637 11.9405 5.26915 11 5.09236V5Z"
        fill="#fff"
      ></path>
    </svg>,
  ];
  const Home = [
    <svg xmlns="http://www.w3.org/2000/svg" class="ionicon" viewBox="0 0 512 512"><path d="M261.56 101.28a8 8 0 00-11.06 0L66.4 277.15a8 8 0 00-2.47 5.79L63.9 448a32 32 0 0032 32H192a16 16 0 0016-16V328a8 8 0 018-8h80a8 8 0 018 8v136a16 16 0 0016 16h96.06a32 32 0 0032-32V282.94a8 8 0 00-2.47-5.79z" /><path d="M490.91 244.15l-74.8-71.56V64a16 16 0 00-16-16h-48a16 16 0 00-16 16v32l-57.92-55.38C272.77 35.14 264.71 32 256 32c-8.68 0-16.72 3.14-22.14 8.63l-212.7 203.5c-6.22 6-7 15.87-1.34 22.37A16 16 0 0043 267.56L250.5 69.28a8 8 0 0111.06 0l207.52 198.28a16 16 0 0022.59-.44c6.14-6.36 5.63-16.86-.76-22.97z" /></svg>
  ]
  const count = [
    {
      today: "Today’s Consuptions",
      title: "(Kwh) 230",
      persent: "+30%",
      icon: dollor,
      bnb: "bnb2",
    },
    {
      today: "Weekly Consuptions",
      title: "(Kwh) 230",
      persent: "+20%",
      icon: dollor,
      bnb: "bnb2",
    },
    {
      today: "Monthly Consuptions",
      title: "(Kwh) 230",
      persent: "-20%",
      icon: dollor,
      bnb: "redtext",
    },
    {
      today: "Yearly Consuptions",
      title: "(Kwh) 230",
      persent: "-20%",
      icon: dollor,
      bnb: "redtext",
    }
  ];
  const [inputValue, setInputValue] = useState('');

  
  const handleChange = (event) => {
    setInputValue(event.target.value);
  };

  async function Propertyname(event) {
    let payload;
    payload = {
      name: inputValue,
      username: 'varanasi'
    }
    try {
      const result = await UserService.createProperty(payload);

      event.preventDefault();

      result = JSON.stringify(payload);
      console.log('Input value:', payload);

      console.log(payload);

    } catch (error) {

      console.log('error...', error);
    }
  }
  const location = useLocation();
  const param = "front";
  const [configDevices, setConfigDevices] = useState(null);
  const [devices, setDevices] = useState(null);

  async function getLastestate(configDev) {
    try {
      const lastStates = await UserService.getDevicesByProperty(propertyId);
      if (lastStates) {
        console.log(lastStates);
        let allLocationDevices = configDev;
        allLocationDevices.forEach((data, index) => {
          if (
            lastStates[0].devices[data.applianceId] &&
            'speed' in lastStates[0].devices[data.applianceId]
          ) {
            data.props = {
              state: lastStates[0].devices[data.applianceId]
                ? lastStates[0].devices[data.applianceId].state
                : false,
              speed: lastStates[0].devices[data.applianceId]
                ? lastStates[0].devices[data.applianceId].speed
                : 3,
              timeStamp: lastStates[0].devices[data.applianceId]
                ? lastStates[0].devices[data.applianceId].timeStamp
                : null,
            };
          } else if (
            lastStates[0].devices[data.applianceId] &&
            'value' in lastStates[0].devices[data.applianceId]
          ) {
            data.props = {
              state: lastStates[0].devices[data.applianceId]
                ? lastStates[0].devices[data.applianceId].state
                : false,
              value: lastStates[0].devices[data.applianceId]
                ? lastStates[0].devices[data.applianceId].value
                : '',
              timeStamp: lastStates[0].devices[data.applianceId]
                ? lastStates[0].devices[data.applianceId]?.timeStamp
                : null,
              ...lastStates[0].devices[data.applianceId],
            };
          } else {
            data.props = {
              state: lastStates[0].devices[data.applianceId]
                ? lastStates[0].devices[data.applianceId].state
                : false,
              timeStamp: lastStates[0].devices[data.applianceId]
                ? lastStates[0].devices[data.applianceId]?.timeStamp
                : null,
              ...lastStates[0].devices[data.applianceId],
            };
          }
        });
        console.log(allLocationDevices);
        setDevices(allLocationDevices);

      }
    } catch (error) {
      console.log('error...', error);
      /* by default set all devices default values*/
      let allLocationDevices = configDev;
      setDevices(allLocationDevices);
    }
  };

  useEffect(() => {
    async function getConfig() {
      try {
        const result = await UserService.getConfig(propertyId);
        console.log(propertyId)
        console.log(result);
        if (result) {
          setConfigDevices(result.devices);
          getLastestate(result.devices);
          getGraphAnalytics(result.devices);
        }
      } catch (error) {
        console.log('error...', error);
      }
    }

    async function getGraphAnalytics(_devices){
      try{
        const sensorlogs = await UserService.getSensorLogs();
        console.log('fffff', _devices)
        let roomwiseAnalytics;
        if(_devices && sensorlogs){
          roomwiseAnalytics = sensorlogs.map((log)=>{
            let newLog={
              type:'',
              temperature:'',
              site:''
            };
            _devices.forEach(device=>{
              console.log('firstOla', device['site'])
              if(log.appliance_id === device.applianceId){
                newLog.temperature=log.value;
                newLog.site = device['site'];
              }
            })
            return newLog;
          })


          const groupBySite = (data) => {
            return data.reduce((acc, curr) => {
              const site = curr.site;
              const temp = parseFloat(curr.temperature);
              
              if (!acc[site]) {
                acc[site] = { max: temp, min: temp };
              } else {
                acc[site].max = Math.max(acc[site].max, temp);
                acc[site].min = Math.min(acc[site].min, temp);
              }
          
              return acc;
            }, {});
          };
          
          // Group data by site and calculate max/min temperatures
          const groupedData = groupBySite(roomwiseAnalytics);
          
          // Create documents for max and min temperatures
          const result = [];
          for (const site in groupedData) {
            result.push({
              type: 'max',
              temperature: groupedData[site].max,
              site: site
            });
            result.push({
              type: 'min',
              temperature: groupedData[site].min,
              site: site
            });
          }
          
          console.log('triple h',result);
          setGraph2(result);



        }else
          console.log('louuuu', 'dev',_devices, 'sde',sensorlogs);
        console.log('sensorLogs', roomwiseAnalytics);
      }catch(e){
        console.log(e);
      }
    }

    
   
    getConfig();
    // getDeviceOnCount();
    // getGraphAnalytics();
  }, [propertyId]);
  
  // const [propertyId, setPropertyId] = useState(null);


  async function getLocations(propertyid) {
    try {
      console.log(propertyid);
      const result = await UserService.getLocations(propertyid);
      console.log(result);
      if (result.sites) {
        setSites(
          result.sites.map(el => {
            return {
              label: el.name,
              locations: el.locations,
            };
          }),
        );
      }
    } catch (error) {
      console.log('error...', error);
    }
  }

  const header = {
    background: "#8C8F9C",
    color: "white",
    width: "270px",
    height: "150px",
    margin: "10px",
    borderRadius: "20px",
    boxSizing: "borderBox",
    boxShadow: isHovering ? "5px 3px 5px #c5c5c5" : "",
    border: `1.7px solid ${"#8C8F9C"}`,
    cursor: "pointer",
    position: "relative",
    transition: "1000ms",
    AnimationDuration: "3s",
    AnimationIterationCount: "1",
    AnimationDirection: "reverse",
    appearance: "auto",
    AnimationName: "bounce",
    transition: "box-shadow .20s ease-in-out",
    transition: "background-position 0.5s ease-in-out",
  }
  function goToLocation(site, location) {
    console.log("change!!");
    let props = {
      propertyId: propertyId,
      siteName: site,
      locationName: location
    };
    navigate.push('Location', { params: props });
  }
  useEffect(() => {
    async function getProperty() {
      try {
        const result = await UserService.getProperty('varanasi');
        console.log(result);
        if (result) {
          setProperty(result[0].property.name);
          getLocations(result[0].property.id);
        }
      } catch (error) {
        console.log('error...', error);
      }
    }
    getProperty();
  }, []);

console.log(devices)

  return (
    <>
      <div className="layout-content">
        <Row className="rowgap-vbox" gutter={[24, 0]}>
          <div style={{display:'flex', gap:'20px', width:'100%', height:'250px',}}>

            <div style={{height:'100%', background:'white', borderRadius:'10px', padding:'10px', flex:1}}>
              <div style={{display:'flex', flexDirection:'column', gap:'20px', height:'100%'}}>
                <div style={{padding:'0px 2px 0px', fontWeight:'500'}}>Device Count Analysis of this week</div>
                
                <div style={{height:'100%'}}><AntBarChart/></div>
              </div>
            </div>

            <div style={{height:'100%', background:'white',borderRadius:'10px', padding:'10px', flex:1}}>
            
              <div style={{display:'flex', flexDirection:'column', gap:'20px', height:'100%'}}>
                <div style={{padding:'0px 2px 0px', fontWeight:'500'}}>Temperature Extremes Overview {`(Today)`}</div>
                
                <div style={{height:'100%'}}>
                {
                graph2!==null ? 
                 graph2.length!==0 ?
                 <AntDoubleBarChart data={graph2}/>
                  : <div style={{display:'flex', alignItems:'center', justifyContent:'center', height:'100%'}}>No analytics found !</div>
                : <EllipsisSpinner/>

              }
                </div>
              </div>
            
            </div>

          </div>
        </Row>
        <Row className="rowgap-vbox" gutter={[24, 0]}>
          <Space direction="vertical" size="middle" style={{ display: 'flex', paddingTop: 10, width: "100%" }}>
            <Card style={{ backgroundColor: 'white', paddingBottom: '30px' }} title="Favourite Devices" size="small">
              <Row gutter={[16, 16]}>
                {devices ?
                  devices.filter(data => data.favourite === true).map((data, index) => (
                    <Col key={'fav'+index} gutter={6}>
                      {data.deviceType === '0x101' ?
                        <SensorCard props={data} />
                        : data.deviceType === '0x01' ?
                          <SwitchCard props={data} />
                          :
                          <SwitchCard props={data} />
                      }
                    </Col>
                  )) : <EllipsisSpinner/>}
              </Row>
            </Card>
          </Space>
        </Row>

        <Row className="rowgap-vbox" gutter={[24, 0]}>
          <Space direction="vertical" size="middle" style={{ display: 'flex', paddingTop: 10, width: "100%" }}>
            <Card style={{ backgroundColor: 'white', paddingBottom: '30px' }} title="Running Devices" size="small">
              <Row gutter={[16, 16]}>
                {devices ?
                  devices.filter(data => data.props.state === true).map((data, index) => (
                    <Col gutter={6}>
                      {data.deviceType === '0x101' ?
                        <SensorCard props={data} />
                        : data.deviceType === '0x01' ?
                          <SwitchCard props={data} />
                          :
                          <SwitchCard props={data} />
                      }
                    </Col>
                  )) : <EllipsisSpinner/>}
              </Row>
            </Card>
          </Space>
        </Row>
      </div>
    </>
  );
}

export default Home;

