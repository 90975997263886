import React, { useState, useEffect } from 'react';
import ReactDOM from 'react-dom';
import { Column } from '@ant-design/plots';
import moment, { min } from 'moment';
import { UserService } from '../../services/user_profile_api';
import EllipsisSpinner from '../common/EllipsisSpinner';

export const AntBarChart = () => {
  // const data = [
  //   {
  //     type: '19.00',
  //     sales: 38,
  //   },
  //   {
  //     type: '20.00',
  //     sales: 52,
  //   },
  //   {
  //     type: '21.00',
  //     sales: 61,
  //   },
  //   {
  //     type: '22.00',
  //     sales: 145,
  //   },
  //   {
  //     type: '01.00',
  //     sales: 48,
  //   },
  //   {
  //     type: '02.00',
  //     sales: 38,
  //   },
  //   {
  //     type: '03.00',
  //     sales: 38,
  //   },
  //   {
  //     type: '05.00',
  //     sales: 38,
  //   },
  // ];
  const [data, setData]= useState();

  useEffect(()=>{
    (async()=>{
      try{
        const devicesOnCount = await UserService.getDeviceOnCount();
      
        const don = devicesOnCount.map((item, index)=>{
          const time = moment(item.timebucket);
          const formattedDateTime = time.format("DD/MM");
          if(Number(index)<7)
            return {
              period: formattedDateTime,
              count: Number(item.devicecounts)
            }
          else
            return null
        }).filter(item=>item!==null);
        setData(don);
        console.log('DON', don);
      }catch(e){
        console.log(e);
        setData([]);
      }
    })();
  },[]);

  const config = {
    data,
    xField: 'period',
    yField: 'count',
    label: {
      position: 'middle',
      style: {
        fill: '#ffffff',
        opacity: 1,
      },
    },
    xAxis: {
      label: {
        autoHide: true,
        autoRotate: false,
      },
      min:0
    },
    meta: {
      period: {
        alias: 'period',
      },
      count: {
        alias: 'count',
      },
    },
    color:'#6ba4fa'
  };
  return (
    <>
      {
      data!==null ? 
       data ?
       <Column {...config} />
        : <div style={{display:'flex', alignItems:'center', justifyContent:'center', height:'100%'}}>No analytics found !</div>
      : <EllipsisSpinner/>

    }
    </>
    
  );
};