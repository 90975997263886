import React, { useEffect } from "react";
import {
  Card,
  Col,
  Row,
  Typography,
  Carousel,
  Empty,
  Button,
  Drawer,
  Input,
  Form,
  message,
  DatePicker
} from "antd";
import { Link, useHistory, useParams, useLocation } from 'react-router-dom';
import { useState } from "react";
import { MdLabel, MdOutlineDeviceThermostat } from "react-icons/md"
import {
  MenuUnfoldOutlined,
  SettingFilled,
  InfoCircleOutlined,
  PoweroffOutlined
} from "@ant-design/icons";
import ThemeColor from "../../assets/theme/ThemeColor";
// import { Line } from '@ant-design/charts';
import { RiHeart3Fill } from 'react-icons/ri';
import { AiFillSetting, AiFillInfoCircle } from "react-icons/ai";
import { Line, Column, Area } from '@ant-design/plots';
import { UserService } from '../../services/user_profile_api';
import moment from "moment";
import Paragraph from "antd/lib/typography/Paragraph";
import "../../assets/styles/main.css";
import "../../assets/styles/responsive.css";
import DateTimePicker from 'react-datetime-picker';
import AreaChart from "../chart/AreaChart";
import { FaArrowLeftLong, FaArrowRightLong, FaArrowUpLong } from "react-icons/fa6";

const SensorDetail = ({ props }) => {
  const navigate = useHistory();
  const devices = props;
  const params = useLocation();
  const device = params?.state?.data;
  const [data, setData] = useState([]);
  const [lastOneDayData, setLastOneDayData] = useState([]);
  const [lastOneMonthData, setLastOneMonthData] = useState([]);
  const { Title, Text } = Typography;
  const [deviceName, setDeviceName] = useState(params.state.data.name);
  const [deviceSiteName, setDeviceSiteName] = useState(params.state.data.site);
  const [deviceLocationName, setDeviceLocationName] = useState(params.state.data.location);
  const [visible, setVisible] = useState(false);
  const [childrenDrawer, setChildrenDrawer] = useState(false);
  const showDrawer = () => setVisible(true);
  const hideDrawer = () => setVisible(false);
  const [messageApi, contextHolder] = message.useMessage();
  const [favourite, setFavourite] = useState(device.favourite ? device.favourite : false);
  const [dates, setDates] = useState({ from: '', to: '' });
  const [chartAxis, setChartAxis] = useState(false);

  const showChildrenDrawer = () => {
    setChildrenDrawer(true);
  };

  const onChildrenDrawerClose = () => {
    setChildrenDrawer(false);
  };

  const [form] = Form.useForm();

  const handleDeviceNameChange = (event) => {
    setDeviceName(event.target.value);
  };

  const handleDeviceSiteNameChange = (event) => {
    setDeviceSiteName(event.target.value);
  };

  const handleDeviceLocationNameChange = (event) => {
    setDeviceLocationName(event.target.value);
  };

  async function updateOnSubmit() {
    const name = deviceName;
    const site = deviceSiteName;
    const location = deviceLocationName;
    const payload = {
      ...device,
      name: name,
      site: site,
      location: location,
    };
    try {
      const response = await UserService.updateDevice(
        payload,
        params.state.propertyId,
      );
      messageApi.open({
        type: 'success',
        content: 'Saved successfully',
        duration: 10,
      });
    } catch (error) {
      messageApi.open({
        type: 'error',
        content: 'Something Went Wrong',
        duration: 10,
      });
    }
  };

  useEffect(() => {
    OneDayMetricsData();
  }, []);

  function onSubmit() {
    getMetricsData([dates.from, dates.to]);
    setChartAxis(true);
  }

  async function getMetricsData(dates) {
    try {
      const result = await UserService.getMetricsbyDateRange(params.state.data.applianceId, dates);
      console.log(result)
      if (result) {
        let newData = result.map(item => {
          const time = moment(item.time);
          const formattedDateTime = time.format("DD/MM hh:mm A");
          return {
            time: formattedDateTime,
            value: Number(item.value)
          };
        });
        setData(newData);
      }
    } catch (error) {
      console.log("error...", error);
    }
  }

  // useEffect(()=>{
  //   getLastOneDayMetricsData();
  // },[]);

  // useEffect(()=>{
  //   getLastOneMonthMetricsData();
  // },[]);

  async function OneDayMetricsData() {
    try {
      const result = await UserService.getLastOneDayMetricsData(params.state.data.applianceId);
      console.log(result);
      if (result) {
        // const sorted = [...result].sort((a, b) => new Date(a.time) - new Date(b.time));
        // console.log(sorted);
        let newdata = result.map(item => {
          const time = moment(item.time);
          const formattedDateTime = time.format("hh:mm A");
          // const formattedDateTime = item.time;
          return {
            time: formattedDateTime,
            value: Number(item.value),
          };
        });
        setData(newdata);
      }
    } catch (error) {
      console.log('error...', error);
    }
  }

  async function getLastOneDayMetricsData() {
    try {
      const result = await UserService.getLastOneDayMetricsData(params.state.data.applianceId);
      if (result) {
        setLastOneDayData(result);
      }
    } catch (error) {
      console.log('error...', error);
    }
  }

  async function getLastOneMonthMetricsData() {
    try {
      const result = await UserService.getLastOneMonthMetricsData(params.state.data.applianceId);
      if (result) {
        setLastOneMonthData(result);
      }
    } catch (error) {
      console.log('error...', error);
    }
  }
  const modifiedData = data.map(item => {
    // Extracting only the date part from the timestamp
    const date = new Date(item.time).toLocaleDateString();
    return { ...item, time: date }; // Updating the 'time' property with the date
  });

  const BarChartConfig = {
    data,
    padding: 'auto',
    xField: 'time',
    yField: 'value',
    yAxis: {
      min: 0,
      nice: true,
    },
    xAxis: {
      tickCount: data.length,
    },
    meta: {
      value: {
        alias: 'Temp',
        nice: true,
      },
      time: {
        tickInterval: 1,
        alias: `time`,
      },
    },
    brush: {
      enabled: true,
      action: 'highlight',
    },
    slider: {
      start: 0.1,
      end: 0.5,
    },
  };

  const LineChartConfig = {
    data,
    padding: 'auto',
    xField: 'time',
    yField: 'value',
    xAxis: {
      tickCount: 5,
    },
    slider: {
      start: 0.1,
      end: 0.5,
    },
  };

  const LastOneDayBarChartConfig = {
    data,
    xField: 'time',
    yField: 'value',
    meta: {
      value: {
        alias: 'Temp',
        nice: true,
      },
      time: {
        tickInterval: 1,
        alias: `time`,
      },
    },
    brush: {
      enabled: true,
      action: 'highlight',
    },
  };

  const LastOneDayLineChartConfig = {
    data,
    padding: 'auto',
    xField: 'time',
    yField: 'value',
    xAxis: {
      tickCount: 5,
    },
    slider: {
      start: 0.1,
      end: 0.5,
    },
  };

  const LastOneMonthBarChartConfig = {
    data,
    xField: 'bucket',
    yField: 'value',
    meta: {
      value: {
        alias: 'Temp',
        nice: true,
      },
      time: {
        tickInterval: 1,
        alias: `time`,
      },
    },
    brush: {
      enabled: true,
      action: 'highlight',
    },
  };

  const LastOneMonthLineChartConfig = {
    data,
    padding: 'auto',
    xField: 'bucket',
    yField: 'value',
    xAxis: {
      tickCount: 5,
    },
    slider: {
      start: 0.1,
      end: 0.5,
    },
  };

  const onChange = (currentSlide) => {
    console.log(currentSlide);
  };

  async function hearthandle() {
    setFavourite(!favourite);
    const payload = {
      applianceId: params.state.data.applianceId,
      favourite: !favourite,
    };
    try {
      const response = await UserService.fav(
        payload,
        params.state.propertyId,
      );
      const data = await JSON.stringify(response)
      const result = await UserService.getConfig(params.state.propertyId,);
      const filt = [...result.devices].filter(item => item.applianceId == params.state.data.applianceId);
      setFavourite(filt[0].favourite)
      console.warn(response);
    } catch (error) {
      console.warn(error);
      setFavourite(!favourite);
    }
  }

  function handleDates(e, index) {
    if (e?._d && e?._d?.moment) {
    }
    const date = moment(e._d);
    const formattedDate = date.format('YYYY-MM-DD');
    if (index === 0) {
      dates.from = formattedDate
    } else if (index === 1) {
      dates.to = formattedDate
    }
    setDates({ ...dates });
  }

  return (
    <div className="layout-content">
      <Card extra={<><RiHeart3Fill
        style={{ color: favourite ? "red" : "grey", fontSize: 28, marginRight: "12px" }}
        onClick={hearthandle}
      />
        <AiFillSetting onClick={showDrawer} style={{ fontSize: 28, marginRight: "2px" }} /> <AiFillInfoCircle onClick={showChildrenDrawer} style={{ fontSize: 28, marginLeft: "10px", marginRight: "10px" }} />
        <Drawer
          title="Setting"
          className="settings-drawer"
          mask={true}
          width={570}
          onClose={hideDrawer}
          placement={"right"}
          visible={visible}
          footer={<Text style={{ color: 'gray' }}><center>Manufacturer <br /> HASHh Automations connected <br />through HASHh Cloud</center></Text>}
          footerStyle={{ fontSize: "18px", fontWeight: "bold", background: "white" }}
        >
          <Text style={{ fontSize: "16px", marginLeft: "15px", fontWeight: "bold" }}>Name</Text>
          <Input placeholder="Enter Device Name" value={deviceName} onChange={handleDeviceNameChange} style={{ borderRadius: "8px", padding: "10px", marginBottom: "15px", marginTop: "10px", marginLeft: "10px" }} />
          <Text style={{ fontSize: "16px", marginLeft: "15px", fontWeight: "bold" }}>Site</Text>
          <Input placeholder="Enter Site" value={deviceSiteName} onChange={handleDeviceSiteNameChange} style={{ borderRadius: "8px", padding: "10px", marginBottom: "15px", marginTop: "10px", marginLeft: "10px" }} />
          <Text style={{ fontSize: "16px", marginLeft: "15px", fontWeight: "bold" }}>Location</Text>
          <Input placeholder="Enter Location" value={deviceLocationName} onChange={handleDeviceLocationNameChange} style={{ borderRadius: "8px", padding: "10px", marginBottom: "15px", marginTop: "10px", marginLeft: "10px" }} />
          <Text style={{ fontSize: "16px", marginLeft: "15px", fontWeight: "bold" }}>Sensor Type</Text>
          <Typography style={{ borderRadius: "8px" }}>
            <pre style={{ padding: "10px", borderRadius: "8px", width: "520px", marginLeft: "10px" }}>{params.state.data.deviceType === '0x101' ? 'TEMPREATURE' : ''}</pre>
          </Typography>
          <Text style={{ fontSize: "16px", marginLeft: "15px", fontWeight: "bold" }}>Device IP Voltage</Text>
          <Typography style={{ borderRadius: "8px" }}>
            <pre style={{ padding: "10px", borderRadius: "8px", width: "520px", marginLeft: "10px" }}>{params.state.data.meta.voltage}</pre>
          </Typography>
          <Text style={{ fontSize: "16px", marginLeft: "15px", fontWeight: "bold" }}>Device IP Current</Text>
          <Typography style={{ borderRadius: "8px" }}>
            <pre style={{ padding: "10px", borderRadius: "8px", width: "520px", marginLeft: "10px" }}>{params.state.data.meta.current}</pre>
          </Typography>
          <center>
            {contextHolder}
            <Button type="primary" htmlType="submit" style={{ borderRadius: "8px", width: "520px", height: "auto", padding: "10px", marginLeft: "10px", marginTop: "15px" }} onClick={updateOnSubmit}>
              Save
            </Button>
            <Button type="primary" htmlType="submit" style={{ borderRadius: "8px", width: "520px", height: "auto", padding: "10px", marginLeft: "10px", marginTop: "15px" }} danger>
              Delete
            </Button>
          </center>
        </Drawer>
        <Drawer
          title="Information"
          className="settings-drawer"
          mask={true}
          width={570}
          onClose={onChildrenDrawerClose}
          placement={"right"}
          visible={childrenDrawer}
          footer={<Text style={{ color: 'gray' }}><center>Manufacturer <br /> HASHh Automations connected <br /> through HASHh Cloud</center></Text>}
          footerStyle={{ fontSize: "18px", fontWeight: "bold", background: "white" }}
        >
          <Row gutter={18}>
            <Text style={{ fontSize: "16px", fontWeight: "bold", marginLeft: "10px", marginRight: "10px", paddingBottom: "10px" }}>Device ID : {params.state.data.deviceId}</Text><br />
          </Row>
          <Row gutter={18}>
            <Text style={{ fontSize: "16px", fontWeight: "bold", marginLeft: "10px", marginRight: "10px", paddingBottom: "10px" }}>Model : {params.state.data.meta.model}</Text><br />
          </Row>
          <Row gutter={18}>
            <Text style={{ fontSize: "16px", fontWeight: "bold", marginLeft: "10px", marginRight: "10px", paddingBottom: "10px" }}>Build :</Text><br />
          </Row>
          <Row gutter={18}>
            <Text style={{ fontSize: "16px", fontWeight: "bold", marginLeft: "10px", marginRight: "10px", paddingBottom: "10px" }}>Firemware Version  :</Text><br />
          </Row>
          <Row gutter={18}>
            <Text style={{ fontSize: "16px", fontWeight: "bold", marginLeft: "10px", marginRight: "10px", paddingBottom: "10px" }}>Vendor ID   : {params.state.data.meta.vendorId}</Text><br />
          </Row>
          <Row gutter={18}>
            <Text style={{ fontSize: "16px", fontWeight: "bold", marginLeft: "10px", marginRight: "10px", paddingBottom: "10px" }}>Signal Strength(dbm)  :</Text><br />
          </Row>
        </Drawer></>}>
        <Carousel afterChange={onChange}>
          <div className="sensor-carousel-contentStyle">
            <Title level={5} style={{ marginBottom: 24 }}>{chartAxis !== true ? 'Last One Hour' : 'Statistics'} </Title>
            <Row gutter={[24, 0]}>
              <Col xs={24} sm={24} md={12} lg={12} xl={12} className="mb-24">
                <Card bordered={false} className="criclebox h-full">
                  <div style={{}}>
                    <Title level={5} style={{ marginTop: '20px' }}>
                      Select Start and End DateTime
                    </Title>
                    <div style={{}}>
                      {[1, 2].map((data, index) => {
                        return (
                          <div key={index}>
                            <Paragraph>
                              {index ? "End DateTime" : "Start DateTime"}
                            </Paragraph>
                            <DatePicker
                              showTime
                              style={{ width: "100%" }}
                              onChange={(e) => handleDates(e, index)}
                            // value={dates[index]}
                            />
                          </div>
                        );
                      })}
                      <div style={{ marginTop: "36px" }}>
                        <Button
                          type="primary"
                          className="width-100"
                          onClick={() => onSubmit()}
                        >
                          Apply
                        </Button>
                      </div>
                    </div>
                  </div>
                </Card>
              </Col>
              {/* <Col xs={24} sm={24} md={12} lg={12} xl={12} className="mb-24">
                <Card bordered={false} className="criclebox h-full" style={{ overflowX: 'auto', maxWidth: '100%', display: 'flex' }}>
                  
                  {data ?
                    data.length ?
                      <Column {...BarChartConfig} />
                      : <Empty />
                    : <Empty />
                  }
                </Card>
              </Col> */}
              <Col xs={24} sm={24} md={12} lg={12} xl={12} className="mb-24">
                <div style={{ display: 'flex', flexDirection: 'column' }}>
                  <div style={{display:'flex'}}>
                    <div style={{color:'black', display:'flex', alignItems:'center', justifyContent:'center', flexDirection:'column' }}>
                      <FaArrowUpLong className="fa-arrow-long-up"/>
                      <div className='rotate_left_90' style={{color:'black', height:'0px', alignSelf:'center'}}>Temperature</div>
                    </div>
                    <div style={{ width: '90%', overflow: 'scroll' }}>
                      <AreaChart data={data} />
                    </div>
                  </div>
                  <div style={{ color: 'black', display: 'flex', alignItems: 'center', justifyContent: 'center', gap: '10px' }}>
                    <div>Time</div>
                    <FaArrowRightLong />
                    </div>
                </div>
              </Col>

            </Row>
          </div>
          <div className="sensor-carousel-contentStyle">
            <Title level={5} style={{ marginBottom: 24 }}>Last One Day</Title>
            <Row gutter={[24, 0]}>
              <Col xs={24} sm={24} md={12} lg={12} xl={12} className="mb-24">
                <Card bordered={false} className="criclebox h-full">
                  {lastOneDayData ?
                    lastOneDayData.length ?
                      <Line {...LastOneDayLineChartConfig} />
                      : <Empty />
                    : <Empty />
                  }
                </Card>
              </Col>
              <Col xs={24} sm={24} md={12} lg={12} xl={12} className="mb-24">
                <Card bordered={false} className="criclebox h-full">
                  {lastOneDayData ?
                    lastOneDayData.length ?
                      <Column {...LastOneDayBarChartConfig} />
                      : <Empty />
                    : <Empty />
                  }
                </Card>
              </Col>
            </Row>
          </div>
          <div className="sensor-carousel-contentStyle">
            <Title level={5} style={{ marginBottom: 24 }}>Last One Month</Title>
            <Row gutter={[24, 0]}>
              <Col xs={24} sm={24} md={12} lg={12} xl={12} className="mb-24">
                <Card bordered={false} className="criclebox h-full">
                  {lastOneMonthData ?
                    lastOneMonthData.length ?
                      <Line {...LastOneMonthLineChartConfig} />
                      : <Empty />
                    : <Empty />
                  }
                </Card>
              </Col>
              <Col xs={24} sm={24} md={12} lg={12} xl={12} className="mb-24">
                <Card bordered={false} className="criclebox h-full">
                  {lastOneMonthData ?
                    lastOneMonthData.length ?
                      <Column {...LastOneMonthBarChartConfig} />
                      : <Empty />
                    : <Empty />
                  }
                </Card>
              </Col>
            </Row>
          </div>
        </Carousel>
      </Card>
      {/* </Card> */}
    </div>
  );
}
export default SensorDetail;