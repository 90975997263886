const getTimeAgo = time => {
    const timeStamp = new Date(`${time}`);
    const now = new Date();
    const secondsPast = (now.getTime() - timeStamp.getTime()) / 1000;
    if (secondsPast < 60) {
        return `${parseInt(secondsPast < 0 ? 0 : secondsPast)}s ago`;
    }
    if (secondsPast < 3600) {
        return `${parseInt(secondsPast / 60)}m ago`;
    }
    if (secondsPast <= 86400) {
        return `${parseInt(secondsPast / 3600)}h ago`;
    }
    if (secondsPast > 86400) {
        const day = timeStamp.getDate();
        const month = timeStamp
            .toDateString()
            .match(/ [a-zA-Z]*/)[0]
            .replace(" ", "");
        const year =
            timeStamp.getFullYear() === now.getFullYear()
                ? ""
                : ` ${timeStamp.getFullYear()}`;
        return `${day} ${month}${year}`;
    }
};

const TimeAgo = {
    getTimeAgo
};

export default TimeAgo;
